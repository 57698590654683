import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { PaginatorModule } from "primeng/paginator";

@Component({
	selector: "paggination",
	templateUrl: "./paggination.component.html",
	styleUrl: "./paggination.component.scss",
	standalone: true,
	imports: [PaginatorModule],
})
export class PaginationComponent implements OnInit {

	//INJECT
	private _languageService = inject(LanguagesService);
	private _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
	//PUBLIC
	public currentLang: string;
	public first: number = 0;
	//OUTPUT
	@Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();
	//INPUTS
	@Input() rows: number = 12;
	@Input() totalRecords: number | undefined = 0;

	ngOnInit(): void {
		this._languageService.language$.subscribe({
			next: (res) => {
				this.currentLang = res;
			},
		});
		// activate current page based on query params
		this._listenToCurrentPage();
	}

	private _listenToCurrentPage(): void {
		this._activatedRoute.queryParams.subscribe((params) => {
			const page = params["page"] ? Number(params["page"]) : 1;
			this.first = (page - 1) * this.rows;
		});
	}


	public onPageChange(e: any) {
		this.pageChanged.emit({ pageIndex: e.page + 1, pageSize: e.rows });
		this.first = e.first;
	}
}
